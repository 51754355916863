.terms-conditions {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 20px;
  margin: 20px 0;
  font-size: 14px;
  line-height: 1.5;
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 100%;

  h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    margin-bottom: 15px;
  }

  ul {
    margin: 0 0 15px 0;
    padding-left: 20px;

    li {
      margin-bottom: 8px;
      line-height: 1.4;
    }
  }

  strong {
    font-weight: 600;
  }
}

.collapsible-terms-widget {
  margin: 15px 0;

  .py-checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    
    .terms-text {
      margin-left: 10px;
      font-size: 14px;
      
      .terms-link {
        color: #007bff;
        cursor: pointer;
        text-decoration: underline;
        
        &:hover {
          color: #0056b3;
        }
      }
    }
  }

  .terms-content {
    margin-top: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
}