.pay-as-bank-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.onboarding-form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-actions {
  text-align: center;
  margin-top: 20px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  /* border-radius: 4px; */
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.form-label {
  font-weight: bold;
}

.text-danger {
  color: #dc3545;
}

.text-muted {
  color: #6c757d;
}

.mb-3 {
  margin-bottom: 1rem;
}